<script setup>
import Avatar from "@/Components/Avatar.vue";
import FileUploader from "@/Components/AwsFileUploader.vue";
import SubmitPostModal from "@/Pages/Feed/Partials/SubmitPostModal.vue";
// import FileUploader from "@/Components/FileUploader.vue";
import InputError from "@/Components/InputError.vue";
import Icon from "@/Icons/jobs-icons.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import PostButton from "@/Components/PostButton.vue";
import { Link, usePage } from "@inertiajs/vue3";
import axios from "axios";
import { computed, onMounted, ref } from "vue";
import LinkPreview from "../Partials/LinkPreview.vue";

const emit = defineEmits(["update:data"]);

const form = ref({
    text: "",
    files: [],
    filePath: "",
    fileType: "",
});

const props = defineProps({
    currentorganization: {
        type: Array,
    },
});

const fileUploaderValue = (files) => {
    if (files?.isAws) {
        console.log(files.type, files.path, "file type");
        form.value.filePath = files.path;
        form.value.fileType = files.type;
    } else {
        form.value.files = files;
        console.log(files, "else file type");
    }
};

const fileUploaderRef = ref(null);
const clearFilesHandler = () => {
    if (fileUploaderRef.value) {
        fileUploaderRef.value.clearFiles();
    }
};
const buttonLoading = ref(false);
const loading = ref(false);
const linkPreviews = ref([]);
const mediaType = computed(() => {
    return (media) => {
        if (media.type === "image") {
            return "img";
        } else {
            return "iframe";
        }
    };
});

const updateLinkPreviews = () => {
    const text = form.value.text;
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex) || [];
    const currentUrlsSet = new Set(urls);

    // Remove links from linkPreviews
    linkPreviews.value = linkPreviews.value.filter((preview) =>
        currentUrlsSet.has(preview.original)
    );

    // Check if there are new URLs to process
    if (urls.length === 0) {
        return;
    }

    // Set for new URLs that need previews
    const newUrlsSet = new Set(urls);

    // Remove URLs that already have previews
    linkPreviews.value.forEach((preview) =>
        newUrlsSet.delete(preview.original)
    );

    if (newUrlsSet.size > 0) {
        loading.value = true;
        const newUrls = Array.from(newUrlsSet);

        axios
            .put(route("feed.load_url_previews"), { urls: newUrls })
            .then((response) => {
                const handledUrls = response.data.urls || [];
                handledUrls.forEach((url) => {
                    if (
                        !linkPreviews.value.find(
                            (preview) => preview.original === url.original
                        )
                    ) {
                        linkPreviews.value.push(url);
                    }
                });
            })
            .catch((err) => {
                console.log("Error:", err);
            })
            .finally(() => {
                loading.value = false;
            });
    }
};

const handleChange = () => {
    setTimeout(() => {
        updateLinkPreviews();
    }, 500);
};

const handlePaste = () => {
    setTimeout(() => {
        updateLinkPreviews();
    }, 100);
};

const handlePost = async () => {
    buttonLoading.value = true;
    let formData = new FormData();
    formData.append("text", form.value.text);
    if (!form.value.filePath) {
        form.value.files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });
        // formData.append('files', form.value.files);
    } else {
        formData.append("filePath", form.value.filePath);
        formData.append("fileType", form.value.fileType);
    }
    console.log(form, "append");

    try {
        const response = await axios.post(route("news_feed"), formData);
        emit("update:data", response.data.posts);
        form.value.text = "";
        form.value.files = [];
        linkPreviews.value = [];
        buttonLoading.value = false;
        clearFilesHandler();
    } catch (error) {
        console.error("Error:", error);
    }
};

const handleUpdateData = (data) => {
    emit("update:data", data);
};

const uploadPhotoButtonLabel = `
<div class="flex items-center justify-center gap-2 text-sm font-bold cursor-pointer text-primary"
                            >
                                <div class="">
											<svg
        v-if="type == 'photo'"
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.3359 10.698C20.3339 12.168 20.3229 13.416 20.2399 14.441C20.1429 15.631 19.9439 16.625 19.4999 17.45C19.3062 17.8117 19.0602 18.143 18.7699 18.433C17.9369 19.266 16.8769 19.643 15.5329 19.823C14.2199 20 12.5359 20 10.3889 20H10.2829C8.13494 20 6.45294 20 5.13894 19.823C3.79594 19.643 2.73494 19.266 1.90294 18.433C1.16494 17.695 0.782937 16.777 0.580937 15.638C0.380937 14.518 0.344938 13.126 0.337938 11.397C0.335938 10.957 0.335938 10.492 0.335938 10V9.94602C0.335938 7.79802 0.335938 6.11602 0.512938 4.80202C0.692938 3.45902 1.06994 2.39802 1.90294 1.56602C2.73494 0.733023 3.79594 0.356024 5.13894 0.176024C6.30694 0.0190236 7.80894 0.00102344 9.63794 -0.000976562C9.82306 -0.000976562 10.0006 0.0725626 10.1315 0.203463C10.2624 0.334363 10.3359 0.511902 10.3359 0.697024C10.3359 0.882145 10.2624 1.05968 10.1315 1.19058C10.0006 1.32148 9.82306 1.39502 9.63794 1.39502C7.78294 1.39702 6.40394 1.41302 5.32494 1.55802C4.13594 1.71802 3.41894 2.02202 2.88894 2.55202C2.35894 3.08202 2.05594 3.80002 1.89594 4.99002C1.73294 6.20002 1.73094 7.78802 1.73094 10V10.784L2.66294 9.97002C3.07147 9.61259 3.60056 9.42375 4.14308 9.44176C4.68561 9.45977 5.20101 9.68328 5.58494 10.067L9.57494 14.057C9.8845 14.3668 10.2934 14.5574 10.7297 14.5952C11.166 14.6331 11.6016 14.5158 11.9599 14.264L12.2379 14.069C12.7547 13.7058 13.3795 13.5287 14.01 13.5666C14.6405 13.6046 15.2395 13.8554 15.7089 14.278L18.3419 16.648C18.6069 16.091 18.7649 15.36 18.8489 14.328C18.9279 13.356 18.9389 12.176 18.9399 10.698C18.9399 10.5129 19.0135 10.3354 19.1444 10.2045C19.2753 10.0736 19.4528 10 19.6379 10C19.8231 10 20.0006 10.0736 20.1315 10.2045C20.2624 10.3354 20.3359 10.5129 20.3359 10.698Z"
            fill="#212B36"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15.8359 9.00002C13.7149 9.00002 12.6539 9.00002 11.9949 8.34102C11.3359 7.68202 11.3359 6.62102 11.3359 4.50002C11.3359 2.37902 11.3359 1.31802 11.9949 0.659023C12.6539 2.34246e-05 13.7149 2.34842e-05 15.8359 2.34842e-05C17.9569 2.34842e-05 19.0179 2.34246e-05 19.6769 0.659023C20.3359 1.31802 20.3359 2.37902 20.3359 4.50002C20.3359 6.62102 20.3359 7.68202 19.6769 8.34102C19.0179 9.00002 17.9569 9.00002 15.8359 9.00002ZM16.5859 2.50002C16.5859 2.30111 16.5069 2.11035 16.3663 1.96969C16.2256 1.82904 16.0349 1.75002 15.8359 1.75002C15.637 1.75002 15.4463 1.82904 15.3056 1.96969C15.165 2.11035 15.0859 2.30111 15.0859 2.50002V3.75002H13.8359C13.637 3.75002 13.4463 3.82904 13.3056 3.96969C13.165 4.11035 13.0859 4.30111 13.0859 4.50002C13.0859 4.69894 13.165 4.8897 13.3056 5.03035C13.4463 5.17101 13.637 5.25002 13.8359 5.25002H15.0859V6.50002C15.0859 6.69894 15.165 6.8897 15.3056 7.03035C15.4463 7.17101 15.637 7.25002 15.8359 7.25002C16.0349 7.25002 16.2256 7.17101 16.3663 7.03035C16.5069 6.8897 16.5859 6.69894 16.5859 6.50002V5.25002H17.8359C18.0348 5.25002 18.2256 5.17101 18.3663 5.03035C18.5069 4.8897 18.5859 4.69894 18.5859 4.50002C18.5859 4.30111 18.5069 4.11035 18.3663 3.96969C18.2256 3.82904 18.0348 3.75002 17.8359 3.75002H16.5859V2.50002Z"
            fill="#212B36"
        />
    </svg>
                                </div>
                                <span>Photo</span>
                            </div>`;
const uploadVideoButtonLabel = `
<div class="flex items-center justify-center gap-2 text-sm font-bold cursor-pointer text-primary"
                            >
                                <div class="">
											<svg
        v-if="type == 'video'"
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 8.5V7.5C0 4.213 0 2.569 0.908 1.462C1.07409 1.25965 1.25965 1.07409 1.462 0.908C2.57 0 4.212 0 7.5 0C10.787 0 12.431 0 13.538 0.908C13.7404 1.07409 13.9259 1.25965 14.092 1.462C14.794 2.317 14.953 3.493 14.989 5.5L15.659 5.17C17.604 4.198 18.577 3.711 19.289 4.151C20 4.591 20 5.679 20 7.854V8.146C20 10.321 20 11.409 19.289 11.849C18.577 12.289 17.604 11.802 15.659 10.829L14.989 10.5C14.953 12.507 14.794 13.683 14.092 14.538C13.9259 14.7403 13.7403 14.9259 13.538 15.092C12.43 16 10.788 16 7.5 16C4.213 16 2.569 16 1.462 15.092C1.25965 14.9259 1.07409 14.7404 0.908 14.538C0 13.43 0 11.788 0 8.5ZM11.56 5.56C11.7056 5.42219 11.8221 5.25659 11.9027 5.07298C11.9832 4.88937 12.0261 4.69148 12.0288 4.491C12.0316 4.29053 11.9941 4.09154 11.9186 3.9058C11.8432 3.72005 11.7312 3.55133 11.5894 3.40959C11.4476 3.26785 11.2788 3.15598 11.093 3.08059C10.9073 3.0052 10.7083 2.96781 10.5078 2.97065C10.3073 2.97348 10.1094 3.01648 9.92586 3.0971C9.74229 3.17771 9.57674 3.29431 9.439 3.44C9.16589 3.72303 9.01488 4.10201 9.01848 4.4953C9.02208 4.8886 9.18001 5.26474 9.45826 5.54273C9.7365 5.82071 10.1128 5.97828 10.5061 5.98151C10.8994 5.98475 11.2772 5.83337 11.56 5.56Z"
            fill="#212B36"
        />
    </svg>
                                </div>
                                <span>Video</span>
                            </div>`;

const candidateCompletion = ref(null);
const loadCandidateCompletion = async () => {
    await axios
        .get(route("candidate.completion"))
        .then((response) => {
            candidateCompletion.value = response.data.percentage;
        })
        .catch((err) => {
            console.log("Error:", err);
        });
};
const modalOpen = ref(false);
const triggerUpload = ref("");
const handleModalOpen = (trigger) => {
    modalOpen.value = true;
    triggerUpload.value = trigger;
};

const handleModalClose = () => {
    modalOpen.value = false;
    triggerUpload.value = "";
};

onMounted(() => {
    if (!usePage().props.auth.organization) {
        loadCandidateCompletion();
    }
});
</script>

<template>
    <div class="flex-auto shrink-0" id="feed-main">
        <div class="grid">
            <div class="flex items-center gap-3 pb-6 pl-px">
                <div class="">
                    <!-- <h2 class="mb-1 text-3xl font-bold text-primary">{{ $t('Welcome') + ' ' +
                        $page.props.auth.user.firstname + '!' }}</h2> -->
                    <h2
                        class="text-lg leading-[125%] md:leading-normal md:text-xl font-bold text-primary"
                    >
                        {{
                            $t(
                                `Welcome ${$page.props.auth.user.firstname}, to the home of hospitality.`
                            )
                        }}
                    </h2>
                    <Link
                        v-if="
                            !$page.props.auth.organization &&
                            candidateCompletion !== null
                        "
                        href="/candidate"
                        class="flex items-center gap-4 text-sm cursor-pointer text-disabled"
                    >
                        {{
                            $t("Your profile is {p} complete").replace(
                                "{p}",
                                candidateCompletion
                            )
                        }}
                        <!--                         banner, avatar, education, work, about -->
                        <div>
                            <svg
                                width="5"
                                height="10"
                                viewBox="0 0 5 10"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.666866 9.66667C0.511097 9.66698 0.36014 9.61273 0.240199 9.51334C0.103861 9.40031 0.0181003 9.23767 0.00184094 9.06132C-0.0144184 8.88497 0.0401596 8.70939 0.153532 8.57334L3.1402 5L0.260199 1.42C0.148352 1.28227 0.0960196 1.10564 0.114789 0.929216C0.133557 0.752788 0.221879 0.591119 0.360199 0.480004C0.499643 0.357311 0.68395 0.298354 0.868715 0.317336C1.05348 0.336319 1.22195 0.431521 1.33353 0.580004L4.55353 4.58C4.75574 4.826 4.75574 5.18067 4.55353 5.42667L1.2202 9.42667C1.08456 9.5903 0.879022 9.67945 0.666866 9.66667Z"
                                    fill="#637381"
                                />
                            </svg>
                        </div>
                    </Link>
                </div>
            </div>

            <form class="" @submit.prevent="handlePost">
                <div
                    class="grid gap-6 p-3 pb-0 bg-white md:rounded-lg shadow-card"
                >
                    <div class="flex items-center gap-2">
                        <Avatar
                            :user="$page.props.auth.user"
                            :postOrganization="currentorganization"
                            size="10"
                        />
                        <div class="flex-auto">
                            <input
                                @click="modalOpen = true"
                                class="block w-full h-10 text-base resize-none border border-[#919EAB33] rounded-lg placeholder:text-disabled text-primary"
                                :placeholder="
                                    $t(
                                        `What’s on your mind ${$page.props.auth.user.firstname}?`
                                    )
                                "
                                v-model="form.text"
                                @paste="handlePaste"
                                @change="handleChange"
                            />

                            <InputError
                                class="mt-2"
                                :message="form.errors?.text"
                            />
                        </div>
                    </div>
                    <div v-if="linkPreviews.length > 0 || loading">
                        <span class="text-sm text-disabled">{{
                            $t("Preview links from your text:")
                        }}</span>
                        <ul
                            class="grid gap-4 mt-2 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
                        >
                            <li
                                v-for="url in linkPreviews"
                                :key="url.link"
                                class="flex items-center justify-center"
                            >
                                <div
                                    class="w-full max-w-xl overflow-hidden bg-white rounded"
                                >
                                    <template v-if="url.type === 'link'">
                                        <LinkPreview
                                            :is-preview="true"
                                            :src="url.link"
                                        />
                                    </template>
                                    <template v-else-if="url.type === 'error'">
                                        <div class="p-4 text-center">
                                            <p class="text-sm text-red-500">
                                                {{
                                                    $t(
                                                        "Failed to load the link"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <component
                                            :is="mediaType(url)"
                                            :src="url.link"
                                            class="w-full h-64 rounded-t"
                                        />
                                    </template>
                                </div>
                            </li>
                            <li v-if="loading">
                                <loading-spinner />
                            </li>
                        </ul>
                    </div>

                    <div
                        class="flex items-center justify-between flex-shrink-0 gap-2 overflow-auto border-t min-h-12 border-secondary-10 whitespace-nowrap"
                        :class="[
                            form.files.length > 0 ? 'flex-col' : 'flex-row',
                        ]"
                    >
                        <div class="z-10 block w-full py-1.5">
                            <div
                                v-html="uploadPhotoButtonLabel"
                                @click="handleModalOpen('photo')"
                            ></div>
                            <!--                            <FileUploader
                                class="feed"
                                ref="fileUploaderRef"
                                :class="[form.files.length > 0]"
                                :labelIdle="uploadPhotoButtonLabel"
                                :allowMultipleFiles="true"
                                :allowReorder="true"
                                :allowUpload="true"
                                @update:modelValue="fileUploaderValue"
                                id="adasjkdzxhcaoqoiwui0000000123456"
                                :upload-path="route('upload.files')"
                            />
                            <InputError
                                class="mt-2"
                                :message="form.errors?.files"
                            />-->
                        </div>
                        <div class="z-10 block w-full py-1.5">
                            <div
                                v-html="uploadVideoButtonLabel"
                                @click="handleModalOpen('video')"
                            ></div>
                            <!--                            <FileUploader
                                class="feed"
                                ref="fileUploaderRef"
                                :class="[form.files.length > 0]"
                                :labelIdle="uploadVideoButtonLabel"
                                :allowMultipleFiles="true"
                                :allowReorder="true"
                                :allowUpload="true"
                                @update:modelValue="fileUploaderValue"
                                id="adasjkdzxhcaoqoiwui0000000123457"
                                :upload-path="route('upload.files')"
                            />
                            <InputError
                                class="mt-2"
                                :message="form.errors?.files"
                            />-->
                        </div>
                        <div
                            class="z-10 block w-full py-1.5 cursor-pointer"
                            @click="modalOpen = true"
                        >
                            <div
                                class="flex items-center justify-center gap-2 text-sm font-bold cursor-pointer"
                            >
                                <div class="">
                                    <Icon type="post" />
                                </div>
                                <span>Make a post</span>
                            </div>
                        </div>
                        <!-- <div class="">
                            <div
                                class="mr-3"
                                :class="[
                                    form.files.length > 0
                                        ? 'w-full mr-0'
                                        : 'w-40',
                                ]"
                            ></div>

                            <PostButton :disabled="buttonLoading" type="submit">
                                <template v-if="buttonLoading">
                                    <LoadingSpinner />
                                </template>
                                <template v-else>
                                    {{ $t("Post") }}
                                </template>
                            </PostButton>
                        </div> -->
                    </div>
                </div>
            </form>
        </div>
    </div>
    <SubmitPostModal
        :modalOpen="modalOpen"
        :trigger="triggerUpload"
        @closeModal="handleModalClose"
        @update:data="handleUpdateData"
    />
</template>

<style>
.feed .filepond--root {
    font-size: 14px;
    margin-bottom: 0;
    font-family: "Montserrat", sans-serif;
}
.feed .filepond--panel-root {
    background-color: inherit;
    cursor: pointer;
}

.feed .filepond--root .filepond--drop-label {
    min-height: 2.25em !important;
    border-radius: 9999px !important;
}
</style>
